.sf-navbar {
  /*background: linear-gradient(to right, rgba(0, 200, 200, 0.2), rgba(200, 200, 0, 0.2)) !important;*/
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2)) !important;
  /*background: rgba(0, 0, 0, 0.8) !important;*/
  backdrop-filter: blur(12px);
}
.sf-logo {
  /*background: linear-gradient(to right, rgba(120, 120, 0, 0.6), rgba(0, 120, 120, 0.6)) !important;*/
  /*background: linear-gradient(to right, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)) !important;*/
  /*background: linear-gradient(to right, rgba(160, 0, 128, 0.6) 0%, rgba(128, 0, 255, 0.6) 100%) !important;*/
  /*padding-left: 30px;
  padding-right: 30px;
  border-radius: 10px 0px;*/
}
.sf-logo-image {
  height: 80px;
  margin: 0px;

  /* invert colors */
  /*filter: invert(1);*/
}
.sf-button {
  /* background: linear-gradient(to right, rgba(200, 200, 0, 0.2), rgba(0, 200, 200, 0.2)) !important; */
  background: linear-gradient(to right, rgba(100, 100, 100, 0.1), rgba(100, 100, 100, 0.1)) !important;
  border: 1px solid #b5b5b5;
}
.sf-button:hover {
  background: linear-gradient(to right, rgba(100, 100, 100, 0.2), rgba(100, 100, 100, 0.2)) !important;
  border: 1px solid #b5b5b5;
}
.sf-document {
  padding-top: 50px;
  padding-bottom: 150px;
}
@media screen and (min-width: 1200px) {
  .sf-responisve-wide-hide {
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  .sf-responisve-narrow-hide {
    display: none;
  }
}
.sf-besidedoc{
  margin-left: 100px;
  margin-top: 150px;
}
.sf-docfield {
  /* layout - zwo childs beside, right one 500px and floating right, left one fill */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}
.sf-document a:hover {
  color: white;
  background-color: #1e87f0;
  text-decoration: none;
}